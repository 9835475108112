export default class Typewriter {

    private readonly _element: HTMLElement | undefined;

    get element(): HTMLElement {
        if (!this._element)
            throw new Error("Element does not exist")
        return this._element;
    }

    private readonly _wordList: string[] | undefined;

    get wordList(): string[] {
        if (!this._wordList)
            throw new Error("Word list does not exist")
        return this._wordList;
    }

    index: number = 0;
    deleteSpeed: number = 50;
    writeSpeed: number = 150;
    delay: number = 1000;

    constructor(elementId: string, wordList: string[]) {
        const elem = document.getElementById(elementId);
        if (!elem)
            return;
        this._element = elem;
        this._wordList = wordList;
        this.element.innerText = this.wordList[0];
    }

    public run(): void {
        setTimeout(() => this.removeChar(), this.delay);
    }

    private nextIndex(): void {
        this.index++;
        if (this.index >= this.wordList.length)
            this.index = 0;
    }


    private removeChar(): void {
        let text = this.element.innerText;
        if (text.length <= 0) {
            this.nextIndex();
            this.addChar();
            return;
        }
        this.element.innerText = text.slice(0, -1);
        setTimeout(() => this.removeChar(), this.deleteSpeed);
    }

    private addChar(): void {
        let text = this.element.innerText;
        const current = this.wordList[this.index];
        if (text.length >= current.length) {
            this.run();
            return;
        }

        this.element.innerText = current.substring(0, text.length + 1);
        setTimeout(() => this.addChar(), this.writeSpeed);
    }
}
