import home from "./de-DE/home.json"
import features from "./de-DE/features.json"
import footer from "./de-DE/footer.json"
import legal from "./de-DE/legal.json"
import prices from "./de-DE/prices.json"
import contact from "./de-DE/contact.json"
import test from "./de-DE/test-signora.json"

export default Object.assign({},
    home,
    features,
    footer,
    legal,
    prices,
    contact,
    test
)
