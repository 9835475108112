













































































import Vue from "vue";
import VueI18n from "vue-i18n";
import PageHeading from "@/components/PageHeading.vue";
import LocaleMessage = VueI18n.LocaleMessage;

export default Vue.extend({
    name: "Prices",
    data() {
        return {
            price: {
                licence: "29,90",
                player: "399,00"
            }
        }
    },
    components: {
        "page-heading": PageHeading
    },
    methods: {
        testSignora() {
            this.$router.push("testen");
        },
        text(key: string): LocaleMessage {
            return this.$t(`prices.${key}`);
        }

    }
});

