

























































import Vue from "vue";
import VueI18n from "vue-i18n";
import {isValidMailData, MailData, sendMail} from "@/utils/mailHelper";
import LocaleMessage = VueI18n.LocaleMessage;

export default Vue.extend({
    name: "ContactForm",
    data() {
        return {
            buttonClass: "button-disabled",
            textContactBox: "" as LocaleMessage,
            overlayActive: false,
            contact: {
                name: "",
                company: "",
                email: "",
                message: ""
            }
        }
    },
    props: {},
    components: {},
    computed: {},
    methods: {
        text(key: string): LocaleMessage {
            return this.$t(`contact.${key}`);
        },
        responseHandler(responseCode: number) {
            if (responseCode === 204)
                this.textContactBox = this.text("sent.success");
            else
                this.textContactBox = this.text("sent.failure");
        },
        getMailData(): MailData {
            return {
                name: this.contact.name,
                company: this.contact.company,
                email: this.contact.email,
                message: this.contact.message
            }
        },
        validateInput() {
            if (isValidMailData(this.getMailData()))
                this.buttonClass = "button"
            else
                this.buttonClass = "button-disabled"
        },
        validateAndSend(): void {
            if (!sendMail(this.getMailData(), this.responseHandler))
                return
            this.buttonClass = "button-disabled"
            this.overlayActive = true;
        }
    },
    mounted() {
        this.textContactBox = this.text("sent.pending");
    }
});

