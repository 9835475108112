



















import Vue from "vue";
import ContactForm from "@/components/ContactForm.vue";
import PageHeading from "@/components/PageHeading.vue";
import VueI18n from "vue-i18n";
import LocaleMessage = VueI18n.LocaleMessage;

export default Vue.extend({
    name: "TestSignora",
    components: {
        "contact-form": ContactForm,
        "page-heading": PageHeading
    },
    methods: {
        text(key: string): LocaleMessage {
            return this.$t(`test-signora.${key}`);
        },
        list(key: string): LocaleMessage {
            return this.$t(`test-signora.${key}`);
        }
    }
});

