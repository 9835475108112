









import Vue from "vue";
import FeatureTemplate from "@/views/features/helper/FeatureTemplate.vue";

export default Vue.extend({
    name: "WebApp",
    data() {
        return {
            id: "web-app",
            imgSrc: require("../../assets/illus/features/web-app.webp")
        }
    },
    components: {
        "feature-template": FeatureTemplate
    }
});

