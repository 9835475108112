var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navigation"},[_c('div',{staticClass:"menu-default"},_vm._l((_vm.routes),function(r){return _c('div',[_c('router-link',{attrs:{"to":r.path},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{class:_vm.getClass(r.path),attrs:{"href":href},on:{"click":function (x) { return _vm.navButton(x, navigate); }}},[_vm._v(_vm._s(r.name))])]}}],null,true)})],1)}),0),_c('div',{class:_vm.mobileMenuClasses},[_c('div',{staticClass:"menu-button",on:{"click":_vm.toggleMenu}},[(!_vm.mobileMenu.active)?_c('i',{staticClass:"fal fa-bars"}):_c('i',{staticClass:"fal fa-times"})]),_c('div',{staticClass:"buttons"},_vm._l((_vm.routes),function(r){return _c('div',[_c('router-link',{attrs:{"to":r.path},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{class:_vm.getClass(r.path),attrs:{"href":href},on:{"click":function (x) { return _vm.navButton(x, navigate); }}},[_vm._v(_vm._s(r.name))])]}}],null,true)})],1)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }