







import Vue from "vue";
import VueI18n from "vue-i18n";
import LocaleMessage = VueI18n.LocaleMessage;

export default Vue.extend({
    name: "AnchorButton",
    data() {
        return {}
    },
    props: {
        to: {
            type: String,
            required: true
        }
    },
    components: {},
    computed: {},
    methods: {
        text(key: string): LocaleMessage {
            return this.$t(`anchorButton.${key}`);
        },
        gotTo() {
            this.$router.push(this.to);
        },
    }
});

