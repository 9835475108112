















import Vue from "vue";

import Welcome from "@/views/home/Welcome.vue";
import BestPieces from "@/views/home/BestPieces.vue";
import AdaptsToYourCompany from "@/views/home/AdaptsToYourCompany.vue";
import AdaptsToYourData from "@/views/home/AdaptsToYourData.vue";
import AdaptsToYou from "@/views/home/AdaptsToYou.vue";
import SimpleAndReliable from "@/views/home/SimpleAndReliable.vue";

export default Vue.extend({
    name: "Home",
    components: {
        "welcome": Welcome,
        "best-pieces": BestPieces,
        "adapts-to-your-company": AdaptsToYourCompany,
        "adapts-to-your-data": AdaptsToYourData,
        "adapts-to-you": AdaptsToYou,
        "simple-and-reliable": SimpleAndReliable
    }
});

