














import Vue from "vue";
import Navigation from "@/components/Navigation.vue";

export default Vue.extend({
    name: "HEADER",
    data() {
        return {
            logoLocked: false,
            logoClass: "logo-hidden"
        }
    },
    components: {
        "navigation": Navigation
    },
    methods: {
        handleScroll: function () {
            if (!this.logoLocked && window.scrollY < 120)
                this.logoClass = "logo-hidden"
            else
                this.logoClass = "logo-visible"
        },
        lockLogo: function () {
            this.logoLocked = this.$route.path !== "/";
            this.handleScroll();
        }
    },
    mounted() {
        this.lockLogo();
    },
    watch: {
        $route: function () {
            this.lockLogo();
        }
    }
});

