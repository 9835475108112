

























import Vue from "vue";
import VueI18n from "vue-i18n";
import Typewriter from "@/helper/Typewriter";
import LocaleMessage = VueI18n.LocaleMessage;

export default Vue.extend({
    name: "SimpleAndReliable",
    data() {
        return {
            elementId: `reliable-example-${Math.floor(Math.random() * Math.pow(10, 10))}`,
            typewriter: {} as Typewriter
        }
    },
    components: {},
    computed: {},
    methods: {
        testSignora() {
            this.$router.push("testen");
        },
        text(key: string): LocaleMessage {
            return this.$t(`home.${key}`);
        },
        list(key: string): LocaleMessage {
            return this.$t(`home.${key}`);
        }
    },
    mounted() {
        this.typewriter = new Typewriter(this.elementId,
            this.list("simple-and-reliable.list") as Array<string>);
        this.typewriter.run();
    }
});

