
















































import Vue from "vue";
import VueI18n from "vue-i18n";
import Typewriter from "@/helper/Typewriter";
import LocaleMessage = VueI18n.LocaleMessage;

export default Vue.extend({
    name: "VariableContent",
    data() {
        return {
            elementId: "perfect-example",
            typewriter: {} as Typewriter
        }
    },
    components: {},
    methods: {
        text(key: string): LocaleMessage {
            return this.$t(`features.${key}`);
        },
        list(key: string): LocaleMessage {
            return this.$t(`features.${key}`);
        }
    },
    mounted() {
        this.typewriter = new Typewriter(this.elementId,
            this.list("variable-content.perfect-for-you.list") as Array<string>);
        this.typewriter.run();
    }
});

