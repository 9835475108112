









import Vue from "vue";
import FeatureTemplate from "@/views/features/helper/FeatureTemplate.vue";

export default Vue.extend({
    name: "UserRights",
    data() {
        return {
            id: "user-rights",
            imgSrc: require("../../assets/illus/features/user-rights.webp")
        }
    },
    components: {
        "feature-template": FeatureTemplate
    }
});

