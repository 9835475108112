




















import Vue from "vue";
import VueI18n from "vue-i18n";
import LocaleMessage = VueI18n.LocaleMessage;

export default Vue.extend({
    name: "FeatureTemplate",
    props: {
        mainId: {
            type: String,
            required: true
        },
        textPrefix: {
            type: String,
            required: true
        },
        imageAlt: {
            type: String,
            required: true
        },
        imageSrc: {
            type: String,
            required: true
        }
    },
    methods: {
        text(key: string): LocaleMessage {
            return this.$t(`features.${this.textPrefix}.${key}`);
        },
        list(key: string): LocaleMessage {
            return this.$t(`features.${this.textPrefix}.${key}`);
        }
    }
});

