
















import Vue from "vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import ContactForm from "@/components/ContactForm.vue";
import {Route} from "vue-router";

export default Vue.extend({
    data() {
        return {
            contactButtonVisible: true,
            contactFormVisible: false
        }
    },
    components: {
        "signora-header": Header,
        "signora-footer": Footer,
        "contact-form": ContactForm
    },
    methods: {
        goToContact() {
            this.$router.push("/testen");
        },
        hideContactButtonIfPageTest(route: Route) {
            this.contactButtonVisible = route.name !== "Test";
        }
    },
    mounted() {
        this.hideContactButtonIfPageTest(this.$route);
        window.history.scrollRestoration = "manual";
    },
    watch: {
        $route: function (to, from) {
            this.hideContactButtonIfPageTest(to);
        }
    }
});

