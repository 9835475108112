




























import Vue from "vue";

export default Vue.extend({
    name: "Navigation",
    data() {
        return {
            route: "-",
            mobileMenu: {
                active: false
            },
            routes: [
                {path: "/", name: "home"},
                {path: "/features", name: "features"},
                {path: "/preise", name: "preise"},
                {path: "/testen", name: "testen"}
            ]
        }
    },
    components: {},
    computed: {
        mobileMenuClasses() {
            let classes = "menu-mobile";
            if (this.mobileMenu.active)
                classes += " active";
            return classes;
        }
    },
    methods: {
        toggleMenu() {
            this.mobileMenu.active = !this.mobileMenu.active;
        },
        navButton(param: MouseEvent, navigate: (arg0: MouseEvent) => void) {
            navigate(param);
        },
        getClass(path: string): string {
            if (path === this.route)
                return "button router-link-exact-active";
            return "button";
        }
    },
    watch: {
        $route: function (to, from) {
            this.route = to.path;
            this.mobileMenu.active = false;
        }
    },
    mounted() {
        this.route = this.$route.path;
    }
});

