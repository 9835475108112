interface ObjectRotation {
    angle: number,
    rotation: number
    roundsPerSecond: number
}

interface Particle {
    img: HTMLImageElement,
    left: number,
    objectRotation: ObjectRotation,
    top: number,
    variationLeft: number,
    variationTop: number,
    opacity: number
}


const baseUrl = "/points"
const ticksPerSecond = 60;

function randBool(): boolean {
    return Math.random() < 0.5;
}

function numberOfParticles(): number {
    return Math.ceil(window.innerWidth / 100);
}

function particleSpeed(): number {
    return 40 / ticksPerSecond;
}

function getImageSrc(): string {
    const particleImages = [
        "p1.webp",
        "p2.webp",
        "p3.webp",
        "p4.webp",
        "p5.webp",
        "p6.webp",
        "p7.webp",
        "p8.webp",
        "p9.webp"
    ];

    return `${baseUrl}/${particleImages[Math.floor(Math.random() * particleImages.length)]}`
}

function newParticle(top: number, left: number): Particle {
    const img = new Image();
    img.src = getImageSrc();

    const opacity = (): number => {
        const maxDelta = 5
        let val = 10 - Math.floor(Math.random() * maxDelta)
        return val / 10;
    }
    const variation = (): number => {
        const base = 5
        let val = (base / 2) - Math.floor(Math.random() * base)
        return val / 10;
    }

    return {
        left: left, top: top, img: img,
        opacity: opacity(),
        objectRotation: {
            angle: 0,
            rotation: 0,
            roundsPerSecond: 0.06
        },
        variationLeft: variation(),
        variationTop: variation(),
    }
}

function createInitialParticles(canvasHeight: number, canvasWidth: number): Particle[] {
    const particles: Particle[] = [];
    for (let i = 0; i < numberOfParticles(); i++) {
        const img = new Image();
        img.src = getImageSrc();

        const left = Math.floor(Math.random() * canvasWidth);
        const top = Math.floor(Math.random() * canvasHeight);

        particles.push(newParticle(top, left));
    }
    return particles;
}

function createParticle(canvasHeight: number, canvasWidth: number): Particle {
    const img = new Image();
    img.src = getImageSrc();

    let left = 0;
    let top = canvasHeight;

    if (randBool()) {
        left = Math.floor(Math.random() * canvasWidth) - 20;

    } else {
        top = Math.floor(Math.random() * canvasHeight) + 20;
    }

    return newParticle(top, left);
}

function setNewObjectRotation(particle: Particle) {
    const or = particle.objectRotation;
    or.rotation = or.angle * Math.PI / 180;
    or.angle += 360 / ticksPerSecond * or.roundsPerSecond;
}

export function initParticles(canvasId: string) {
    const canvas = document.getElementById(canvasId) as HTMLCanvasElement;
    if (!canvas)
        return
    const ctx = canvas.getContext('2d');
    if (!ctx)
        return;

    const particles = createInitialParticles(canvas.height, canvas.width);

    const draw = () => {
        const wHeight = window.innerHeight;
        const wWidth = window.innerWidth;
        ctx.clearRect(0, 0, wWidth, wHeight);


        for (let i = 0; i < numberOfParticles() - particles.length; i++) {
            particles.push(createParticle(wHeight, wWidth))
        }
        for (let i = 0; i < particles.length; i++) {
            const p = particles[i];

            ctx.save();

            setNewObjectRotation(p)
            ctx.globalAlpha = p.opacity;

            p.left += (particleSpeed() + p.variationLeft);
            p.top -= (particleSpeed() + p.variationTop);
            const x = p.left;
            const y = p.top;

            if (y < 0 - 20 || x > wWidth + 20) {
                particles.splice(i, 1);
                ctx.restore();
                continue;
            }

            ctx.translate(x, y);
            ctx.rotate(p.objectRotation.rotation);

            const imgWidth = p.img.width / 2;
            const imgHeight = p.img.height / 2;
            ctx.drawImage(p.img, -imgWidth / 2, -imgHeight / 2, imgWidth, imgHeight);
            ctx.restore();
        }
        window.requestAnimationFrame(draw);
    }
    window.requestAnimationFrame(draw);
}


