

































import Vue from "vue";
import VueI18n from "vue-i18n";
import PageHeading from "@/components/PageHeading.vue";
import LocaleMessage = VueI18n.LocaleMessage;

export default Vue.extend({
    name: "Overview",
    components: {
        "page-heading": PageHeading
    },
    methods: {
        scrollToPlayer() {
            this.scrollTo("#player");
        },
        scrollToWebApp() {
            this.scrollTo("#web-app");
        },
        scrollTo(id: string) {
            const element = document.querySelector(id)
            if (!element)
                return;
            const topPos = element.getBoundingClientRect().top + window.pageYOffset

            window.scrollTo({
                top: topPos,
                behavior: "smooth"
            })
        },
        text(key: string): LocaleMessage {
            return this.$t(`features.overview.${key}`);
        },
        list(key: string): LocaleMessage {
            return this.$t(`features.overview.${key}`);
        }
    }
});

