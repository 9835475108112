

















import Vue from "vue";
import VueI18n from "vue-i18n";
import LocaleMessage = VueI18n.LocaleMessage;

export default Vue.extend({
    name: "BestPieces",
    components: {},
    methods: {
        text(key: string): LocaleMessage {
            return this.$t(`home.${key}`);
        },
        list(key: string): LocaleMessage {
            return this.$t(`home.${key}`);
        }
    }
});

