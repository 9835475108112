





















import Vue from "vue";
import VueI18n from "vue-i18n";
import AnchorButton from "@/components/AnchorButton.vue";
import LocaleMessage = VueI18n.LocaleMessage;

export default Vue.extend({
    name: "AdaptsToYou",
    components: {
        "anchor-button": AnchorButton
    },
    methods: {
        text(key: string): LocaleMessage {
            return this.$t(`home.${key}`);
        },
        list(key: string): LocaleMessage {
            return this.$t(`home.${key}`);
        }
    }
});

