import Vue from 'vue';
import VueI18n from 'vue-i18n';
import deDE from "./locales/de-DE";
import {Locales} from "./locales";

Vue.use(VueI18n);

const messages = {
    [Locales.deDE]: deDE
};

const i18n = new VueI18n({
    messages,
    locale: Locales.deDE,
    fallbackLocale: Locales.deDE
});

export default i18n;
