import axios from "axios";

interface MailData {
    name: string,
    email: string,
    company: string,
    message: string
}

function isValidName(name: string): boolean {
    return name.length >= 2;
}

function isValidEmailAddress(email: string): boolean {
    return /(.+)@(.+){2,}\.(.+){2,}/.test(email);
}

function isValidCompanyName(company: string): boolean {
    return company.length >= 2;
}

function isValidMailData(data: MailData): boolean {
    return isValidName(data.name) && isValidCompanyName(data.company) && isValidEmailAddress(data.email);
}


function sendMail(data: MailData, callback: (statusCode: number) => void): boolean {
    if (!isValidMailData(data))
        return false;
    axios.post("../contact-form.php",
        {
            name: data.name,
            email: data.email,
            company: data.company,
            message: data.message
        })
        .then(function (response) {
            callback(response.status)
        })
        .catch(function (error) {
            console.log(error);
            callback(error.response.status)
        })
    return true;
}

export {
    MailData,
    isValidMailData,
    sendMail
};
