




































import Vue from "vue";
import VueI18n from "vue-i18n";
import LocaleMessage = VueI18n.LocaleMessage;

export default Vue.extend({
    name: "Footer",
    components: {},
    methods: {
        footer(key: string): LocaleMessage {
            return this.$t(`footer.${key}`);
        },
        scrollToTop(): void {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }
    }
});

