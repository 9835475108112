















import Vue from "vue";
import VueI18n from "vue-i18n";
import Particle from "@/components/Particle.vue";
import LocaleMessage = VueI18n.LocaleMessage;

export default Vue.extend({
    name: "Welcome",
    components: {
        "particle": Particle
    },
    methods: {
        text(key: string): LocaleMessage {
            return this.$t(`home.${key}`);
        },
        list(key: string): LocaleMessage {
            return this.$t(`home.${key}`);
        }
    }
});

