













import Vue from "vue";

import Overview from "@/views/features/Overview.vue";
import WebApp from "@/views/features/WebApp.vue";
import Cloud from "@/views/features/Cloud.vue";
import UserRights from "@/views/features/UserRights.vue";
import VariableContent from "@/views/features/VariableContent.vue";
import Player from "@/views/features/Player.vue";
import SimpleAndReliable from "@/views/home/SimpleAndReliable.vue";

export default Vue.extend({
    name: "Features",
    components: {
        "overview": Overview,
        "web-app": WebApp,
        "cloud": Cloud,
        "user-rights": UserRights,
        "variable-content": VariableContent,
        "player": Player,
        "simple-and-reliable": SimpleAndReliable
    },
    methods: {}
});

