import Vue from "vue"
import VueRouter, {RouteConfig} from "vue-router"
import Home from "../views/Home.vue"
import Features from "../views/Features.vue"
import Prices from "../views/Prices.vue"
import Imprint from "../views/Imprint.vue"
import Privacy from "../views/Privacy.vue"
import Legal from "../views/Legal.vue"
import TestSignora from "../views/TestSignora.vue"
import PageNotFound from "../views/PageNotFound.vue"

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {path: "/", name: "Home", component: Home},
    {path: "/features", name: "Features", component: Features},
    {path: "/preise", name: "Features", component: Prices},
    {path: "/testen", name: "Test", component: TestSignora},
    {path: "/impressum", name: "Imprint", component: Imprint},
    {path: "/datenschutz", name: "Privacy", component: Privacy},
    {path: "/agb", name: "Legal", component: Legal},
    {path: "*", name: "PageNotFound", component: PageNotFound}
]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash,
            }
        }
        return {x: 0, y: 0}
    }
})

export default router
