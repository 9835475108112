import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import i18n from "./i18n"

Vue.config.productionTip = false

Vue.directive("scroll", {
    inserted: function (el, binding) {
        let f = function (evt: any) {
            if (binding.value(evt, el)) {
                window.removeEventListener("scroll", f)
            }
        }
        window.addEventListener("scroll", f)
    }
});

new Vue({
    i18n,
    router,
    render: h => h(App)
}).$mount("#app")
