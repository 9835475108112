

















import Vue from "vue";
import VueI18n from "vue-i18n";
import LocaleMessage = VueI18n.LocaleMessage;

export default Vue.extend({
    name: "PageHeadingTestSignora",
    props: {
        localMessagePrefix: {
            required: true,
            type: String
        }
    },
    methods: {
        isArray(key: string) {
            return Array.isArray(this.$t(`${this.localMessagePrefix}.${key}`));
        },
        text(key: string): LocaleMessage {
            return this.$t(`${this.localMessagePrefix}.${key}`);
        },
        list(key: string): LocaleMessage {
            return this.$t(`${this.localMessagePrefix}.${key}`);
        }
    }
});

