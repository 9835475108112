







import Vue from 'vue';
import {initParticles} from "@/components/helper/particle";

export default Vue.extend({
    name: "Particle",
    data() {
        return {
            canvasId: "particle-canvas",
            height: 0,
            width: 0,
        }
    },
    methods: {
        initCanvas: function () {
            initParticles(this.canvasId);
        },
        setCanvasSize: function () {
            const container = document.getElementById("particle") as HTMLDivElement;
            const canvas = document.getElementById(this.canvasId) as HTMLCanvasElement;
            if (!canvas || !container)
                return;
            this.height = container.clientHeight;
            this.width = container.clientWidth;
            canvas.height = this.height;
            canvas.width = this.width;
        }
    },
    mounted() {
        this.setCanvasSize();
        this.initCanvas();

        const that = this;
        window.onload = window.onresize = function () {
            that.setCanvasSize();
        }
    },
});

